<template>
  <div class="ud-body">
    <a-card :bordered="true">
      <a-form
        ref="form"
        :rules="rules"
        :label-col="labelCol"
        :model="form"
        :wrapper-col="wrapperCol"
        class="form"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="换电柜编号" name="cabinetCode">
              <a-input
                placeholder="请输入换电柜编号"
                v-model:value="form.cabinetCode"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="换电柜名称" name="cabinetName">
              <a-input
                placeholder="请输入换电柜名称"
                v-model:value="form.cabinetName"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="电池编号" name="batteryCode">
              <a-input
                placeholder="请输入电池编号"
                v-model:value="form.batteryCode"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="告警名称" name="alertName">
              <a-select
                v-model:value="form.alertName"
                placeholder="请选择告警名称"
              >
                <a-select-option value="0">单仓故障</a-select-option>
                <a-select-option value="1">换电柜故障</a-select-option>
                <a-select-option value="2">整柜故障</a-select-option>
                <a-select-option value="3">电池告警</a-select-option>
                <a-select-option value="4">电池故障</a-select-option>
                <a-select-option value="5">整柜告警</a-select-option>
                <a-select-option value="6">单仓告警</a-select-option>
              </a-select>
            </a-form-item></a-col
          >
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="告警时间" name="alertTime">
              <a-range-picker
                format="YYYY-MM-DD HH:mm:ss"
                showTime
                :placeholder="['开始日期', '结束日期']"
                @change="onChange"
                style="width: 100%"
                value-format="YYYY-MM-DD HH:mm:ss"
                v-model:value="dateRange"
                @ok="onOk"
              /> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属运营商" name="operator">
              <a-select
                v-model:value="form.alertName"
                placeholder="请选择所属运营商"
              >
                <a-select-option value="0"
                  >厦门远丞科技有限公司</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属租赁点" name="point">
              <a-select
                v-model:value="form.point"
                placeholder="请选择所属租赁点"
              >
                <a-select-option value="0">福建厦门三行网点</a-select-option>
              </a-select>
            </a-form-item></a-col
          >
        </a-row>
        <a-row type="flex" justify="end">
          <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"
              ><i class="iconfont icon-zaitu"></i>查询</a-button
            >
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button
            >
            <span
              v-if="!expand"
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              展开<i class="iconfont icon-xiangxiajiantou"></i>
            </span>
            <span
              v-else
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              收起<i class="iconfont icon-xiangshang_jiantou"></i>
            </span>
          </a-space>
        </a-row>
      </a-form>
    </a-card>
    <a-card :bordered="true" class="table">
      <ud-pro-table
        ref="table"
        :rowKey="(record) => record.cabinetCode"
        :columns="columns"
        :datasource="datasource"
        @change="handleChange"
        :scroll="{ x: 'max-content' }"
      >
        <template #toolbar>
        </template>
        <template #customTitle>
          <div>
            所属运营商
            <i
              class="iconfont icon-bukejian"
              style="cursor: pointer"
              v-if="!isShowOperator"
              @click="isShowOperator = true"
            ></i>
            <i
              class="iconfont icon-kejian"
              style="cursor: pointer"
              v-else
              @click="isShowOperator = false"
            ></i>
          </div>
        </template>
        <template #operatorCustom="{ record }">
          <div v-if="!isShowOperator && record.operatorName">
            {{
              record.operatorName.substring(0, 1) +
              new Array(record.operatorName.length).join('*')
            }}
          </div>
          <div v-else>
            {{ record.operatorName }}
          </div>
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a @click="openEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确定要删除此项目吗？">
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>

    <!-- <real-name-detail v-model:visible="showDetail" :data="current || {}" /> -->
    <!-- <operation-edit v-model:visible="showEdit" :data="current" @done="reload" /> -->
  </div>
</template>

<script>
// import realNameDetail from './realNameDetail.vue'
// import operationEdit from './operation-edit.vue'
// import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import * as cabinetApi from '@/api/rnt/chargeCabinet'
export default {
  components: {
    // realNameDetail
    // DownOutlined,
    // ExclamationCircleOutlined
    // operationDetail,
    // operationEdit
  },
  watch: {
    dateRange() {
      if (this.dateRange && this.dateRange.length === 2) {
        this.form.alertTimeStart = this.dateRange[0]
        this.form.alertTimeEnd = this.dateRange[1]
      } else {
        this.form.alertTimeStart = null
        this.form.alertTimeEnd = null
      }
    }
  },
  data() {
    return {
      visibleLog: false,
      visibleOrder: false,
      visibleEndTime: false,
      visibleRefund: false,
      visibleBatteryCode: false,
      actionForm: { endTime: '' },
      labelCol: { md: { span: 6 }, sm: { span: 24 } },
      wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
      showDetail: false,
      showEdit: false,
      current: null,
      form: {},
      rules: {},
      expand: false,
      isShowOperator: false,
      columns: [
        {
          title: '序号',
          width: 48,
          // align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '换电柜编号',
          // width: 170,
          // align: 'center',
          dataIndex: 'cabinetCode'
        },
        {
          title: '换电柜名称',
          // width: 85,
          // align: 'center',
          dataIndex: 'cabinetName'
        },
        {
          title: '告警名称',
          // width: 175,
          // align: 'center',
          dataIndex: 'alarmDescName'
        },
        {
          title: '电池编号',
          // width: 175,
          // align: 'center',
          dataIndex: 'batteryId'
        },
        {
          title: '仓门号',
          // width: 175,
          // align: 'center',
          dataIndex: 'doorId'
        },
        {
          title: '告警类型',
          // width: 175,
          // align: 'center',
          dataIndex: 'alarmTypeName'
        },
        {
          title: '告警等级',
          // width: 175,
          // align: 'center',
          dataIndex: 'alertLevel'
        },
        {
          title: '告警开始时间',
          // width: 175,
          // align: 'center',
          dataIndex: 'alarmTime'
        },
        {
          title: '告警结束时间',
          // width: 175,
          // align: 'center',
          dataIndex: 'alarmEndTime'
        },
        {
          // width: 175,
          // align: 'center',
          // dataIndex: 'operator',
          slots: {
            title: 'customTitle',
            customRender: 'operatorCustom'
          }
        },
        {
          title: '所属租赁点',
          // width: 175,
          // align: 'center',
          dataIndex: 'pointName'
        }
        // {
        //   title: '操作',
        //   width: 200,
        //   dataIndex: 'action',
        //   align: 'center',
        //   slots: {
        //     customRender: 'action'
        //   }
        // }
      ],
      dateRange: []
      // data: [
      //   {
      //     cabinetCode: 'CHZD04DCJ0210916002',
      //     cabinetName: '明发02',
      //     batteryCode: 'BT97328572357273282289',
      //     brand: '三元锂电',
      //     model: '72v55Ah',
      //     warehouseNumber: '2',
      //     alertGrade: '二级',
      //     alertName: '单芯电压低告警',
      //     alertType: '电池告警',
      //     alertStartTime: '2022-01-14 09:45:36',
      //     alertEndTime: '2022-01-15 09:45:36',
      //     operator: '厦门远丞科技有限公司',
      //     point: '明发2'
      //   },
      //   {
      //     cabinetCode: 'CHZD04DCJ0210916012',
      //     cabinetName: '明发02',
      //     batteryCode: 'BT97328572357273282289',
      //     brand: '三元锂电',
      //     model: '72v55Ah',
      //     warehouseNumber: '2',
      //     alertGrade: '二级',
      //     alertName: '单芯电压低告警',
      //     alertType: '电池告警',
      //     alertStartTime: '2022-01-14 09:45:36',
      //     alertEndTime: '2022-01-15 09:45:36',
      //     operator: '厦门远丞科技有限公司',
      //     point: '明发2'
      //   }
      // ]
    }
  },
  methods: {
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    datasource(option, callback) {
      cabinetApi
        .cabinetAlarm({
          ...this.form,
          page: option.page,
          limit: option.limit,
          sort: 'createTime',
          order: 'desc'
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      // this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.form = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    openEdit(row) {
      console.log(row)
      this.current = row
      this.showEdit = true
    },
    // 新增计费模板确定按钮回调事件
    handleBillingOk() {
      this.$refs.billingForm.validate().then((res) => {
        console.log(res)
        this.$message.success('添加计费模板成功！')
        this.visible = false
      })
    },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    // 表格改变
    handleChange() {}
  }
}
</script>
<style lang='less' scoped>
.form {
  .btn-wrap {
    padding-right: 20px;
    .iconfont {
      margin-right: 5px;
    }
  }
}
.table {
  margin: 10px 0;
}
</style>
